import React from 'react'
import Sidebar from '../../Components/Coman/Sidebar'
import DepositListSlots from '../PoolEntry'

export default function DepositList() {
  return (
    <div>
        <Sidebar/>
        <DepositListSlots/>
    </div>
  )
}
