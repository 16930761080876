import React from 'react'
import Sidebar from '../Components/Coman/Sidebar'
import CountdownTimer from './CountdownTimer'

export default function NewPoolEntry() {
  return (
    <div>
        <Sidebar />
      <section className="dashboard-section body-collapse pay step deposit-money withdraw-money" style={{ background: "#F7F9FD" }}>
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content" style={{ background: "#fff" }}>
              <div className="head-area d-flex align-items-center justify-content-between">
                <h5>Booster Pool Entry</h5>
              </div>

              <div className="row pb-120  justify-content-center">
                <div className="col-12">
                  <div className="table-area">
                    <div className="head-item">
                      {/* <h6>Linked Payment system</h6> */}
                    </div>
                    
                    <div className="card-area d-flex  flex-wrap w-100 justify-content-around">
                      <div className="single-card">
                        <input
                          type="radio"
                          defaultChecked
                          name="cardSelect"
                          id="visa"
                        />
                        <label htmlFor="visa">
                          <div className="time">
                              <CountdownTimer targetDate={'2024-11-15T15:00:00'} />
                          </div>
                          <span className="wrapper" />
                          <img
                          src="assets/img/slote/magic-pool-card.png"
                            alt="image"
                            style={{ width: "350px" }}
                          />
                        </label>
                      </div>
                      <div className="single-card">
                        <input type="radio" name="cardSelect" id="paypal" />
                        <label htmlFor="paypal">
                          <span className="wrapper" />
                          <img
                         src="assets/img/slote/earth-pool-card.png"
                            alt="image"
                            style={{ width: "350px" }}
                          />
                        </label>
                      </div>
                      <div className="single-card">
                        <input type="radio" name="cardSelect" id="paypal" />
                        <label htmlFor="paypal">
                          <span className="wrapper" />
                          <img
                            src="assets/img/slote/global-pool-card.png"
                            alt="image"
                            style={{ width: "350px" }}
                          />
                        </label>
                      </div>
                     
                    </div>
                    <div>
                      <form action="#" >
                        <div className="row justify-content-center mt-5">
                          <div className="col-xl-7 p-4" style={{ background: "#F8F8FF", borderRadius: "20px",boxShadow:" 3px 3px 20px 0px #b0aeae" }}>
                            <div className="exchange-content">
                              <div className="send-banance">
                                <span className="mdr">You Send</span>
                                <div className="input-area">
                                  <input
                                    className="xxlr"
                                    placeholder={400.0}
                                    type="number"
                                  />
                                  {/* <select style={{ background: "none" }}>
                                    <option value={1}>USD</option>
                                    <option value={2}>USD</option>
                                    <option value={3}>USD</option>
                                  </select> */}
                                </div>
                                <p>
                                  Available Balance<b>$30,700.00</b>
                                </p>
                              </div>

                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="footer-area mt-40">
                    {/* <a href="#">Previous Step</a> */}
                    <a href="#" className="active">
                      Stake
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
