import React from 'react'
import Header from '../Coman/Header'
import TermsServicesHero from './TermsServicesHero'
import Footer from '../Coman/Footer'

export default function TermsServices() {
  return (
    <div>
        <Header/>
        <TermsServicesHero/>
        <Footer/>
    </div>
  )
}
