import React from 'react'
import FeesHero from './FeesHero'
import Header from '../Coman/Header'
import FeesMain from './FeesMain'
import FeesCalculator from './FeesCalculator'
import Footer from '../Coman/Footer'

export default function Fees() {
  return (
    <div>
        <Header/>
        <FeesHero/>
        <FeesMain/>
        {/* <FeesCalculator/> */}
        <Footer/>
    </div>
  )
}
