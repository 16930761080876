import React from 'react'
import AbouHero from './AbouHero'
import Header from '../Coman/Header'
import AboutMain from './AboutMain'
import Footer from '../Coman/Footer'

export default function Aboutus() {
  return (
    <div>
        <Header/>
        <AbouHero/>
        <AboutMain/>
        <Footer/>
    </div>
  )
}
