import React from "react";

export default function AffiliationProgram() {
  return (
    <div>
      <section className="how-works inner-how-works index-3" id="join-our-affiliate-program">
        <div className="overlay pt-100 pb-100">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10">
                <div className="section-header text-center">
                  <h4 className="title mb-3">
                    Join Our Affiliate &nbsp;
                    <span className="text-Purple"> Program</span>
                  </h4>
                  <p>
                    Join DollarPay’s affiliate program & start inviting people
                    to us. Everytime your affiliate makes a transaction, you
                    will receive rewards for it.
                  </p>
                </div>
              </div>
            </div>
            <div className="row cus-mar">
              <div className="col-lg-3">
                <div className="single-item d-grid justify-content-center">
                  <div className="icon">
                    <div className="iconbg">
                      <img src="assets/img/new-img/sign_up.png" alt="icon" />
                      <span className="xlr">01</span>
                    </div>
                  </div>
                  <div className="bottom-icon text-center">
                    <h5>Sign Up</h5>
                    <p>
                    Completing signing up at DollarPay & get your affiliate code. 

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-item d-grid justify-content-center">
                  <div className="icon">
                    <div className="iconbg">
                      <img src="assets/img/new-img/invite.png" alt="icon" />
                      <span className="xlr second">02</span>
                    </div>
                  </div>
                  <div className="bottom-icon text-center">
                    <h5>Invite </h5>
                    <p>Invite & Refer your network to DollarPay for business growth.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-item d-grid justify-content-center">
                <div className="icon">
                    <div className="iconbg">
                      <img src="assets/img/new-img/conversion.png" alt="icon" />
                      <span className="xlr">03</span>
                    </div>
                  </div>
                  <div className="bottom-icon text-center">
                    <h5>Conversion</h5>
                    <p>
                    Referral is success when your invited users integrate our products.

                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="single-item d-grid justify-content-center">
                <div className="icon">
                    <div className="iconbg">
                      <img src="assets/img/new-img/earn.png" alt="icon" />
                      <span className="xlr second">04</span>
                    </div>
                  </div>
                  <div className="bottom-icon text-center">
                    <h5>Earn</h5>
                    <p>
                    Start earning commission on every transaction your referee makes. 

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
