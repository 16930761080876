import React from "react";
import Slider from "react-slick";

export default function OurServices() {
  var settings = {
    dots: false,
    infinite: true,
    // autoplay: true,
    // speed: 3000,
    // autoplaySpeed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    // pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots:false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <section className="payment-solutions" id="features">
        <div className="overlay pt-120 pb-120">
          <div className="container-fruid wow fadeInUp">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-header text-center">
                    <h4 className="title text-cener">
                      Our <span className="text-Purple">Services</span>
                    </h4>
                    <p>
                      DollarPay is an all-in-crypto payment solution that
                      enables businesses and merchants to accept or pay
                      cryptocurrencies for goods & services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment-">
              <Slider {...settings}>
                <div>
                  <div className="single text-center">
                    <div className="single-item cur-heigh text-center">
                      <div className="icon">
                        <img src="assets/img/new-img/crypto_payment_gateway.png" alt="icon" />
                      </div>
                      <h6 className="mt-3">Crypto Payment Gateway</h6>
                      <p className="mdr mt-2">
                        Pay your Business payments with great rates and no
                        hidden fee.
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="single text-center">
                    <div className="single-item cur-heigh text-center">
                      <div className="icon">
                        <img src="assets/img/new-img/gas_station.png" alt="icon" />
                      </div>
                      <h6 className="mt-3">Gas Station</h6>
                      <p className="mdr mt-2">
                        Merchants can avoid paying for transaction gas using our
                        payment platform.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="single text-center">
                    <div className="single-item cur-heigh text-center">
                      <div className="icon">
                        <img src="assets/img/new-img/payment_apis.png" alt="icon" />
                      </div>
                      <h6 className="mt-3">Payment APIs</h6>
                      <p className="mdr mt-2">
                        Integrate our APIs into your applications or website to
                        process crypto transactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="single text-center">
                    <div className="single-item cur-heigh text-center">
                      <div className="icon">
                        <img src="assets/img/new-img/payment_invoices.png" alt="icon" />
                      </div>
                      <h6 className="mt-3">Payment Invoices</h6>
                      <p className="mdr mt-2">
                        Generating digital invoices for incoming or outgoing
                        crypto transactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="single text-center">
                    <div className="single-item cur-heigh text-center">
                      <div className="icon">
                        <img src="assets/img/new-img/plugins.png" alt="icon" />
                      </div>
                      <h6 className="mt-3">Plugins</h6>
                      <p className="mdr mt-2">
                        Web plugins for Wordpress, Magento, Opencart, Laravel
                        and other platforms.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
