import React from 'react'
import Sidebar from '../../../Components/Coman/Sidebar'
import HistoryTable from './HistoryTable'

export default function SHistory() {
  return (
    <div>
        <Sidebar/>
        <HistoryTable/>

    </div>
  )
}
