import React from 'react'
import Header from '../Coman/Header'
import FaqHero from './FaqHero'
import FaqMain from './FaqMain'
import Footer from '../Coman/Footer'

export default function FAQ() {
  return (
    <div>
        <Header/>
        <FaqHero/>
        <FaqMain/>
        <Footer/>
    </div>
  )
}
