import React from "react";

export default function Main() {
  return (
    <div>
      <section className="how-works inner-how-works index7 ">
        <div className="overlay pt-120 pb-100" style={{background:"lightgrey"}}>
          <div className="container">
          <div className="row bottom-content justify-content-center">
  <div className="col-xl-4 col-md-6 col-12">
    <div className="single-item d-block d-md-flex ">
      <div className="icon text-center me-2">
        <img src="assets/img/new-img/countries.png" alt="image" />
      </div>
      <div className="text-area">
        <div className="d-flex justify-content-center">
        <h3 className="state-box-sha">20+</h3>
        </div>
        <h6 className="text-center mt-2">Countries </h6>
      </div>
    </div>
  </div>
  <div className="col-xl-4 col-md-6 col-12">
    <div className="single-item d-block d-md-flex ">
      <div className="icon text-center me-2">
      <img src="assets/img/new-img/currencies.png" alt="image" />
      </div>
      <div className="text-area">
        <div className="d-flex justify-content-center">
        <h3 className="state-box-sha">100+</h3>
        </div>
        <h6 className="text-center mt-2">Currencies </h6>
      </div>
    </div>
  </div>
  <div className="col-xl-4 col-md-6 col-12">
    <div className="single-item d-block d-md-flex ">
      <div className="icon text-center me-2">
      <img src="assets/img/new-img/satisfaction.png" alt="image" />
      </div>
      <div className="text-area">
        <div className="d-flex justify-content-center">
        <h3 className="state-box-sha">99%</h3>
        </div>
        <h6 className="text-center mt-2">Satisfaction </h6>
      </div>
    </div>
  </div>
  {/* <div className="col-xl-4 col-md-6 col-12">
    <div className="single-item d-flex align-items-center">
      <div className="icon">
        <img src="assets/img/new-img/currencies.png" alt="image" />
      </div>
      <div className="text-area">
        <h3>100+</h3>
        <p>Currencies</p>
      </div>
    </div>
  </div>
  <div className="col-xl-4 col-md-6 col-12">
    <div className="single-item d-flex align-items-center">
      <div className="icon">
        <img src="assets/img/new-img/satisfaction.png" alt="image" />
      </div>
      <div className="text-area">
        <h3>99%</h3>
        <p>Satisfaction </p>
      </div>
    </div>
  </div>
  <div className="col-xl-3 col-md-6 col-12">
    <div className="single-item d-flex align-items-center b-none">
      <div className="icon">
        <img src="assets/img/new-img/support.png" alt="image" />
      </div>
      <div className="text-area">
        <h3>24/7 	</h3>
        <p>Support</p>
      </div>
    </div>
  </div> */}
</div>

          </div>
        </div>
      </section>
    </div>
  );
}
