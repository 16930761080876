import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../Components/Coman/Sidebar'
import { ProjectContext } from '../ProjectContext';
import axios from 'axios';
import Pagination from '../Components/Coman/Pagination';

export default function MyAllTeam() {
    const { account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "level",
                address: "0x6652e765ccd969816503c40da6e91816517b8691",
                // address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);


    return (
        <>
            <Sidebar />
            <section className="dashboard-section body-collapse transactions">
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="head-area">
                            <div className="row">
                                <div className="col-lg-5 col-md-4">
                                    <h4>My All Team</h4>
                                </div>
                                <div className="col-lg-7 col-md-8">
                                    <div className="transactions-right d-flex align-items-center">
                                        <form action="#" className="flex-fill">
                                            <div className="form-group d-flex align-items-center">
                                                <img src="assets-new/images/icon/search.png" alt="icon" />
                                                <input type="text" placeholder="Type to search..." />
                                            </div>
                                        </form>
                                        <a href="javascript:void(0)">Monthly Statement</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="transactions-main">
                                    <div className="top-items">
                                        <h6>My All Team</h6>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Joining Date</th>
                                                    <th scope="col">Address</th>
                                                    <th scope="col">Referral</th>
                                                    <th scope="col">Direct Team</th>
                                                    <th scope="col">Direct Business</th>
                                                    <th scope="col">Rank</th>
                                                    <th scope="col">Total Slot</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="text-center">
                                                    <td className="text-center" colSpan={10}>
                                                        {isLoading ? "Data is loading" : ""}
                                                    </td>
                                                </tr>
                                                {!isLoading ? (
                                                    !data.length ? (
                                                        <tr className="text-center">
                                                            <td className="text-center" colSpan={10}>
                                                                <span className="w-100">No data found</span>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                {data.map((ele, i) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {ele.id}
                                                            </td>
                                                            <td>
                                                                {ele.createdAt}
                                                            </td>
                                                            <td>
                                                                <span onClick={() =>
                                                                    copyaddress(ele.address)
                                                                }>
                                                                    {formatAddress(ele.address)}  <i className="fa fa-copy"></i>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span onClick={() =>
                                                                    copyaddress(ele.ref_address)
                                                                }>
                                                                    {formatAddress(ele.ref_address)}  <i className="fa fa-copy"></i>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {ele.ref_team}
                                                            </td>
                                                            <td>
                                                                $ {ele.direct_team_business}
                                                            </td>
                                                            <td>
                                                                {ele.rank}
                                                            </td>
                                                            <td>
                                                                $ {ele.t_staking}
                                                            </td>
                                                            <td>
                                                                {ele.status ? (
                                                                    <div className="text-success">
                                                                        Active
                                                                    </div>
                                                                ) : (
                                                                    <div className="text-danger">
                                                                        Not Active
                                                                    </div>
                                                                )}
                                                            </td>
                                                            {/* <td>
                                                    $ {ele.team_business} ({ele.team})
                                                </td> */}


                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        pages={pages}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
