import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from '../../ProjectContext';
import { Link, useNavigate } from 'react-router-dom';

export default function Header() {
  const { account, disconnectNow, dbuser, copyaddress, formatAddress, connectMetamask } = useContext(ProjectContext);
  const [showMenu, setShowMenu] = useState(false);
  var navigate = useNavigate();

  const logout = () => {
    disconnectNow();
    navigate('/')
  }
  // useEffect(() => {
  //     if (dbuser !== null) {
  //         navigate("/dashboard");
  //     } 
  // }, [dbuser, account])

  const currentPath = window.location.pathname;
  // State to manage the visibility of the header content
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the visibility
  const toggleHeader = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <header className="header-section body-collap header-no-fixed">
        <div className="overlay bg-white">
          <div className="container">
            <div className="row d-flex header-area ">
              <nav className="navbar d-flex navbar-expand-lg navbar-dark align-items-center">
                <Link className="navbar-brand" to="/">
                  <img
                    src="assets/img/new-img/logo.svg"
                    className="logo"
                    style={{ width: "80%" }}
                    alt="logo"
                  />
                </Link>
                <button
                  // className="navbar-toggler"
                  className={
                    isOpen
                      ? "navbar-toggler "
                      : "navbar-toggler collapsed"
                  }
                  type="button"
                // data-bs-toggle="collapse"
                // data-bs-target="#navbarNavDropdown"
                // aria-label="Toggle navigation"
                >
                  <i className="fas fa-bars" onClick={toggleHeader} />
                </button>
                <div
                  className={
                    isOpen
                      ? "collapse navbar-collapse justify-content-end show show-1"
                      : "collapse navbar-collapse justify-content-end  "
                  }
                // className="collapse navbar-collapse justify-content-end"
                // id="navbarNavDropdown"
                >
                  <ul className="navbar-nav">
                    {/* <li className="nav-item dropdown">
                      <Link
                        className={`${
                          currentPath === "/dashboard" ||
                          currentPath === "/dashboard"
                            ? " nav-link active"
                            : " nav-link"
                        }`}
                        to="/dashboard"
                        role="button"
                      >
                        APIs
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        to="/fees"
                        className={`${currentPath === "/fees"
                          ? " nav-link active"
                          : " nav-link"
                          }`}
                      >
                        Fees
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`${currentPath === "/plugins"
                          ? " nav-link active"
                          : " nav-link"
                          }`}
                        to="/plugins"
                      >
                        Plugins
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className={`${currentPath === "/coins"
                          ? " nav-link active"
                          : " nav-link"
                          }`}
                        to="/coins"
                        role="button"
                      >
                        Coins
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className={`${currentPath === "/login"
                          ? " nav-link active"
                          : " nav-link"
                          }`}
                        to="/login"
                        role="button"
                      >
                        Log in
                      </Link>
                    </li>
                  </ul>
                  <div className="right-area header-action d-flex align-items-center">
                    {/* <div className="lang d-flex align-items-center">
                      <img src="assets/img/lang.png" alt="icon" />
                      <select>
                        <option value={1}>EN</option>
                        <option value={2}>BN</option>
                        <option value={3}>ES</option>
                        <option value={4}>NL</option>
                      </select>
                    </div> */}
                    {/* <a href="login.html" className="cmn-btn login">
                      Login
                    </a> */}
                    {account ?
                      <Link
                        to="/dashboard"
                        className="cmn-btn"
                        style={{
                          boxShadow: "4px 7px 12px black",
                          width: "200px",
                        }}
                      >
                        {formatAddress(account)}
                      </Link> :
                      <button
                        className="cmn-btn"
                        style={{
                          boxShadow: "4px 7px 12px black",
                          width: "200px",
                        }}
                        onClick={() => connectMetamask()}
                      >
                        Connect Wallet
                      </button>}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
