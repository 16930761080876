import React from "react";
import Sidebar from "../Components/Coman/Sidebar";
import ProgressBar from "@ramonak/react-progress-bar";

export default function AutoPool() {
  return (
    <>
      <Sidebar />
      <section
        className="dashboard-section body-collapse pay step deposit-money withdraw-money"
        style={{ background: "#F7F9FD" }}
      >
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content" style={{ background: "#fff" }}>
              <div className="head-area d-flex align-items-center justify-content-between">
                <h5> Auto Pool</h5>
              </div>
              <section className="benefits-section">
                <div className="overlay  pb-120">
                  <div className="container">
                    <div className="top-wrapper">
                      <div className="row wrapper">
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des ">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                            <div className="binary-children binary-children_level__0">
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                            </div>

                            <div className="binary-children binary-children_level__1">
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des ">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                            <div className="binary-children binary-children_level__0">
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                            </div>

                            <div className="binary-children binary-children_level__1">
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des ">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                            <div className="binary-children binary-children_level__0">
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                            </div>

                            <div className="binary-children binary-children_level__1">
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des ">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                            <div className="binary-children binary-children_level__0">
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                            </div>

                            <div className="binary-children binary-children_level__1">
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des ">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                            <div className="binary-children binary-children_level__0">
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                            </div>

                            <div className="binary-children binary-children_level__1">
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des ">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                            <div className="binary-children binary-children_level__0">
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                              <div className="matrix-children__active">
                                <a href="#" title="UID: 48880">
                                  &nbsp;
                                </a>
                              </div>
                            </div>

                            <div className="binary-children binary-children_level__1">
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                              <div className="matrix-children__nonactive "></div>
                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des py-4">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                          
                            <div className="d-flex gap-5 mt-3 justify-content-center">
                            <div className="br-dete"></div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                         

                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des py-4">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                          
                            <div className="d-flex gap-5 mt-3 justify-content-center">
                            <div className="br-dete"></div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                         

                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des py-4">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                          
                            <div className="d-flex gap-5 mt-3 justify-content-center">
                            <div className="br-dete"></div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                         

                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des py-4">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                          
                            <div className="d-flex gap-5 mt-3 justify-content-center">
                            <div className="br-dete"></div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                         

                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des py-4">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                          
                            <div className="d-flex gap-5 mt-3 justify-content-center">
                            <div className="br-dete"></div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                         

                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="single-item text-center bg-des py-4">
                            <h5 className="text-white">Auto Pool 1</h5>
                            <button
                              type="button"
                              class="btn btn-primary btn-lg py-2 mt-3"
                              style={{ width: "70%" }}
                            >
                              25
                            </button>
                          
                            <div className="d-flex gap-5 mt-3 justify-content-center">
                            <div className="br-dete"></div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                              <div className="bg-black p-2 py-1 rounded-pill" style={{position:"relative",zIndex:"99"}}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                              </div>
                         

                            </div>
                            <div className="d-flex justify-content-between ">
                              <div className="">
                                <h6 className="text-white">User</h6>
                                <h5 className="text-white">5</h5>
                              </div>

                              <div className="">
                                <h6 className="text-white">Name</h6>
                                <h5 className="text-white">5</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}
