import React from 'react'
import Sidebar from '../../Components/Coman/Sidebar'

export default function Withdrawal() {
    return (
        <>
            <Sidebar />
            <section className="dashboard-section body-collapse pay step deposit-money withdraw-money">
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="main-content">
                            <div className="head-area d-flex align-items-center justify-content-between">
                                <h4>Withdraw Funds</h4>
                                <div className="icon-area">
                                    <img src="assets-new/images/icon/support-icon.png" alt="icon" />
                                </div>
                            </div>
                            {/* <div className="choose-recipient">
                                <div className="step-area">
                                    <span className="mdr">Step 2 of 3</span>
                                    <h5>Set Amount of transfer</h5>
                                </div>
                            </div> */}
                            <div className="row pt-120 pb-120 justify-content-center">
                                <div className="col-md-7">
                                    <div className="table-area" style={{boxShadow:" 3px 3px 20px 0px #b0aeae"}}>
                                        <form action="#">
                                            <div className="send-banance">
                                                <span className="mdr">How much you want to add?</span>
                                                <input className="xxlr my-3" placeholder={400.00} type="number" />
                                                <p>Available Balance<b>$30,700.00</b></p>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="footer-area mt-40">
                                        <a href="withdraw-money-step-3.html" className="active">Next</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
