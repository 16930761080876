import React from "react";

export default function FeesHero() {
  return (
    <div>
      <section className="dashboard-section transactions recipients p-0 m-0 w-100">
        <div className="overlay pt-120 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div class="section-header text-center">
                  <h4 class="title text-cener">
                    Fees <span class="text-Purple">Chart</span>
                  </h4>
                  <p>
                    DollarPay provides the lowest transaction fee with no other
                    hidden <br /> charges, nor we charge for the deposit fee,
                    except for the transaction <br /> and processing fees which
                    are down below.
                  </p>
                </div>
                <div className="transactions-main">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20%" }}>
                            CRYPTOCURRENCIES
                          </th>
                          <th scope="col" style={{ width: "25%" }}>
                            SYMBOL{" "}
                          </th>
                          <th scope="col" style={{ width: "25%" }}>
                            TRANSACTION FEE
                          </th>
                          <th scope="col" style={{ width: "15%" }}>
                            PROCESSING FEE
                          </th>
                          <th scope="col" style={{ width: "15%" }}>
                            MINIMUM PAYMENT*
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                              src="assets/img/new-img/n1_48.png"
                                  alt="image"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">Network </p>
                              </div>
                            </div>
                          </th>
                          <td>
                            <p>NET </p>
                            {/* <p className="mdr">10 Mar 2022</p> */}
                          </td>
                          <td>
                            <p>0.0001</p>
                            {/* <p className="mdr">Received</p> */}
                          </td>
                          <td className="">
                            <p>0.23% </p>
                          </td>
                          <td className="">
                            <p>0.00020000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/new-img/btc (1).png"
                                  alt="image"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">Bitcoin</p>
                              </div>
                            </div>
                          </th>
                          <td>
                            <p>BTC</p>
                            {/* <p className="mdr">10 Mar 2022</p> */}
                          </td>
                          <td>
                            <p>0.0001</p>
                            {/* <p className="mdr">Received</p> */}
                          </td>
                          <td className="">
                            <p>0.23% </p>
                          </td>
                          <td className="">
                            <p>0.00020000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/ltc.png"
                                  alt="Litecoin"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">Litecoin</p>
                              </div>
                            </div>
                          </th>
                          <td>
                            <p>LTC</p>
                          </td>
                          <td>
                            <p>0.01 </p>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>0.01500000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/bch.png"
                                  alt="Bitcoin Cash"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">Bitcoin Cash</p>
                              </div>
                            </div>
                          </th>
                          <td>
                            <p>BCH</p>
                          </td>
                          <td>
                            <p>0.01 </p>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>0.01000000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/doge.png"
                                  alt="image"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">DogeCoin</p>
                              </div>
                            </div>
                          </th>
                          <td>
                            <p>DOGE</p>
                          </td>
                          <td>
                            <p>7 </p>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>15.00000000 </p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/tcn.png"
                                  alt="Test-Coin"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">Test Coin</p>
                              </div>
                            </div>
                          </th>
                          <td>
                            <p>TCN </p>
                          </td>
                          <td>
                            <p>0.0001 </p>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>0.00100000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/dash.png"
                                  alt="Dash"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">Dash</p>
                              </div>
                            </div>
                          </th>
                          <td>
                            <p>DASH</p>
                          </td>
                          <td>
                            <p>0.02</p>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>0.03184713</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/trx.png"
                                  alt="Tron"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">
                                  Tron{" "}
                                  <span className="bg-success text-white px-3 rounded-pill ms-3">
                                    Pro
                                  </span>
                                </p>
                              </div>
                            </div>
                          </th>
                          <td>
                            <p>TRX</p>
                          </td>
                          <td>
                            <p>12</p>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>15.00000000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/zano.png"
                                  alt="Zano"
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">Zano</p>
                              </div>
                            </div>
                          </th>
                          <td>
                            <p>Zano</p>
                          </td>
                          <td>
                            <p>0.2</p>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>3.00000000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/eth.png"
                                  alt="Ethereum "
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">
                                  Ethereum{" "}
                                  <span className="bg-success text-white px-3 rounded-pill ms-2">
                                    Pro
                                  </span>
                                </p>
                              </div>
                            </div>
                          </th>

                          <td>
                            <div className="d-flex  align-items-center justify-content-around ">
                              <p className="text-end">ETH</p>
                              <div className="d-flex align-items-center gap-2">
                                <i className="fas fa-gas-pump text-Purple"></i>
                                <span className="bg-success text-white px-3 ta-witth py-2 rounded-pill smr">
                                  With <br />
                                  Gas Station
                                </span>
                                <p>0.003</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center  gap-2 ms-3">
                                <i className="fas fa-gas-pump text-Purple"></i>
                                <span className="bg-danger text-white px-3 ta-witth py-2 rounded-pill smr">
                                  With <br />
                                  Gas Station
                                </span>
                                <p>0.005</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>0.00540000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/usddolor-1.png"
                                  alt="Ethereum "
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">
                                  Tether USD ERC20
                                  <span className="bg-success text-white px-3 rounded-pill ms-2">
                                    Pro
                                  </span>
                                </p>
                              </div>
                            </div>
                          </th>

                          <td>
                            <div className="d-flex  align-items-center justify-content-around ">
                              <p className="text-end">USDTERC20</p>
                              <div className="d-flex align-items-center gap-2">
                                <i className="fas fa-gas-pump text-Purple"></i>
                                <span className="bg-success text-white px-3 ta-witth py-2 rounded-pill smr">
                                  With <br />
                                  Gas Station
                                </span>
                                <p>5</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center  gap-2 ms-3">
                                <i className="fas fa-gas-pump text-Purple"></i>
                                <span className="bg-danger text-white px-3 ta-witth py-2 rounded-pill smr">
                                  With <br />
                                  Gas Station
                                </span>
                                <p>10</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>8.00000000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/bnb-1.png"
                                  alt="Binance Coin "
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">Binance Coin</p>
                              </div>
                            </div>
                          </th>

                          <td>
                            <div className="d-flex  align-items-center justify-content-around ">
                              <p className="text-end">BNB</p>
                              <div className="d-flex align-items-center gap-2">
                                <i className="fas fa-gas-pump text-Purple"></i>
                                <span className="bg-success text-white px-3 ta-witth py-2 rounded-pill smr">
                                  With <br />
                                  Gas Station
                                </span>
                                <p>0.005</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center  gap-2 ms-3">
                                <i className="fas fa-gas-pump text-Purple"></i>
                                <span className="bg-danger text-white px-3 ta-witth py-2 rounded-pill smr">
                                  With <br />
                                  Gas Station
                                </span>
                                <p>0.008</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>0.01500000</p>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <div className="info-area">
                              <div className="img-area">
                                <img
                                  src="assets/img/table-icon/u20-1.png"
                                  alt="Tether USD TRC20  "
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <div className="text-area">
                                <p className="mdr">Tether USD TRC20</p>
                              </div>
                            </div>
                          </th>

                          <td>
                            <div className="d-flex  align-items-center justify-content-around ">
                              <p className="text-end">USDTTRC20</p>
                              <div className="d-flex align-items-center gap-2">
                                <i className="fas fa-gas-pump text-Purple"></i>
                                <span className="bg-success text-white px-3 ta-witth py-2 rounded-pill smr">
                                  With <br />
                                  Gas Station
                                </span>
                                <p>2</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center  gap-2 ms-3">
                                <i className="fas fa-gas-pump text-Purple"></i>
                                <span className="bg-danger text-white px-3 ta-witth py-2 rounded-pill smr">
                                  With <br />
                                  Gas Station
                                </span>
                                <p>4</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>0.23% </p>
                          </td>
                          <td>
                            <p>8.00000000</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <p className="mt-4">
                Users will only be charged above fees when they withdraw funds
                manually or automatically. Deposit is completely free.
              </p>
              <p>
                <b> *Pro:</b> Coins listed with Pro labels can Only Be Accessed
                By Premium Members.
              </p>
              <p>
                <b>*Minimum Payment: </b> Kindly note that transactions with a
                value lower than the minimum payment value, determined by the
                current equivalent value of the specific cryptocurrency, will
                not be reflected in your account. For further details, kindly
                refer to the Minimum Payment column in the table above.
              </p>
              <div className="d-flex justify-content-center mt-3">
                {/* <a href="#" class="cmn-btn mx-1 me-0 me-sm-2 mt-2">
                  Register Now
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
