import React from 'react'
import Header from '../Coman/Header'
import Integration from './Integration'
import GatewayPlugins from './GatewayPlugins'
import Footer from '../Coman/Footer'


export default function Plugins() {
  return (
    <div>
        <Header/>
        <Integration/>
        <GatewayPlugins/>
        <Footer/>
    </div>
  )
}
