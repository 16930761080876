import React, { useState, useContext } from 'react'
import { Line } from "rc-progress";
import { Link } from 'react-router-dom';
import { ProjectContext } from '../ProjectContext';

export default function DashboardMain() {
  const { copyaddress,
    account,
    dbuser,
  } = useContext(ProjectContext)

  return (
    <>
      <section className="dashboard-section body-collapse">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="row">
              <div className="col-xl-8 col-lg-7">
                <div className="section-content">
                  <div className="acc-details ">
                    <div className="top-area d-block">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="">
                            {/* <h4>Hello,User</h4>
                            <p className="text-white">
                              (221321) <i class="fa-solid fa-copy ms-3 c-pointer"></i>
                            </p> */}
                            <h5 className="mt-4">My Account</h5>
                            <p className="text-white mt-1" onClick={() => copyaddress(account)}>
                              {account}<i class="fa-solid fa-copy ms-3 c-pointer"></i>
                            </p>

                            <h5 className="mt-4">Referral Address</h5>
                            <p className="text-white mt-1" onClick={() => copyaddress(dbuser !== null ? dbuser.ref_address : 'no referral')}>
                              {dbuser !== null ? dbuser.ref_address : 'no referral'}
                              <i class="fa-solid fa-copy ms-3 c-pointer"></i>
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <ul className="d-flex align-items-center mt-4 mt-mdo">
                            <li>
                              {" "}
                              <h5 className=" text-white">My Rank: </h5>
                            </li>
                            <li>
                              {" "}
                              <p className="text-white ms-3 mt-1">{dbuser !== null ? dbuser.rank : 'no referral'}</p>
                            </li>
                          </ul>
                          <ul className="d-flex align-items-center mt-3">
                            <li>
                              <h5 className=" text-white">Affiliate link :</h5>
                            </li>
                            <li>
                              {" "}
                              <p className="text-white ms-3 mt-1 text-break" onClick={() => copyaddress(`${process.env.REACT_APP_LINK}${account}`)}>
                                {process.env.REACT_APP_LINK}{account}{" "}
                                <i class="fa-solid fa-copy ms-3 c-pointer"></i>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="bottom-area justify-content-evenly">
                      <div className="left-side">
                        <a
                          href="#"
                          className="cmn-btn"
                          style={{ width: "183px" }}
                        >
                          {" "}
                          Purchase Slots{" "}
                        </a>
                        <a href="#" className="cmn-btn">
                          Pool Entry
                        </a>
                        <a href="#" className="cmn-btn">
                          Withdrawal{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="transactions-area mt-40">
                    <div className="section-text mb-0">
                      <div className="d-flex justify-content-between mb-4">
                        <h5 className="">Reward Capability </h5>
                        <h5 className="">3X</h5>
                      </div>
                      <Line
                        percent={50}
                        strokeColor="#9f24e7"
                        trailColor="#02104d"
                      />
                      <div className="d-flex justify-content-between mt-3">
                        <h6>$100</h6>
                        <h6>$150</h6>
                        <p>$300</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5">
                <div className="side-items">
                  <div className="single-item">
                    <div className="section-text d-flex align-items-center justify-content-between">
                      <h6>Last Paid Booster Account</h6>
                      <div className="view-all d-flex align-items-center">
                        <a href="javascript:void(0)">View All</a>
                        <img
                          src="assets-new/images/icon/right-arrow.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <ul className="recipients-item">
                      <li>
                        <p className="left d-flex align-items-center">
                          <img
                            src="assets-new/images/recipients-1.png"
                            alt="icon"
                          />
                          <span className="info">
                            <span>Magic Booster</span>
                            <span>0000020</span>
                          </span>
                        </p>
                        {/* <p className="right">
                          <span> +$345</span>
                          <span>Payment</span>
                        </p> */}
                      </li>
                      <li>
                        <p className="left d-flex align-items-center">
                          <img
                            src="assets-new/images/recipients-2.png"
                            alt="icon"
                          />
                          <span className="info">
                            <span>Earth  Booster</span>
                            <span>0000020</span>
                          </span>
                        </p>
                        {/* <p className="right">
                          <span className="loss">-$850</span>
                          <span>Refund</span>
                        </p> */}
                      </li>
                      <li>
                        <p className="left d-flex align-items-center">
                          <img
                            src="assets-new/images/recipients-3.png"
                            alt="icon"
                          />
                          <span className="info">
                            <span>Globle Booster</span>
                            <span>0000020</span>
                          </span>
                        </p>
                        {/* <p className="right">
                          <span>+$2.050</span>
                          <span>Payment</span>
                        </p> */}
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="dashboard-section body-collapse pay">
        <div className="overlay">
          <div className="container-fruid">
            <div className="main-content">
              <div className="head-area d-flex align-items-center justify-content-between">
                <h5>Reward Detail</h5>
                <div className="icon-area">
                  <img
                    src="assets-new/images/icon/support-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
              <div className="gap-3 d-flex justify-content-center flex-wrap ">
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr ">Instant Bonus</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Ponser Reward </span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Team Building</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Working Royalty </span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">None Working Royalty</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Rank Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Magic Booster Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Earth  Booster Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Globle Pull Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Globle Reward</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dashboard-section body-collapse pay">
        <div className="overlay">
          <div className="container-fruid">
            <div className="main-content">
              <div className="head-area d-flex align-items-center justify-content-between">
                <h5>Account statistics
                </h5>
                <div className="icon-area">
                  <img
                    src="assets-new/images/icon/support-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
              <div className="gap-3 d-flex justify-content-center flex-wrap">
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Total Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Direct Team</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Total Team</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Current Rank</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Next Rank</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Holding Reward</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Donate Fund</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Total Withdrawal</span>
                  </a>
                </div>
                <div className="" style={{ width: "300px" }}>
                  <a className="single-item box-new-sh">
                    <div className="icon-area">
                      <img
                        src="assets-new/images/icon/paylio-account-icon.png"
                        alt="icon"
                      />
                    </div>
                    <h5 className="h5n">$5</h5>
                    <span className="mdr">Pending Withdrawal</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
