import React from 'react'
import Header from '../Coman/Header'
import Hero from './Hero'
import Main from './Main'
import Footer from '../Coman/Footer'
import OurServices from './OurServices'
import Source from './Source'
import SecureMoney from './SecureMoney'
import LittleIdeas from './LittleIdeas'
import AffiliationProgram from './AffiliationProgram'
import ChooseYourPlan from './ChooseYourPlan'
import HowDoesWork from './HowDoesWork'
import Testimonials from './Testimonials'
import NewsMedia from './NewsMedia'
import Footer2 from '../Coman/Footer2'
import AddToken from './AddToken'

export default function Index() {
  return (
    <div>
        <Header/>
        <Hero/>
        <Main/>
        <OurServices/>
        <Source/>
        <SecureMoney/>
        <LittleIdeas/>
        <AffiliationProgram/>
        {/* <ChooseYourPlan/> */}
        <AddToken/>
        <HowDoesWork/>
        <Testimonials/>
        <NewsMedia/>
        {/* <Footer2/> */}
        <Footer/>   
        
    </div>
  )
}
