import React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function ComingSoon() {
  return (
    <div>
        <Header/>
      <section className="coming-soon">
        <div className="overlay">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 text-center">
               
                <div className="mid-area pt-120">
                  <h1 className="text-white">Coming Soon</h1>
                  <div className="draw-counter d-flex justify-content-center">
                    
                  </div>
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}
