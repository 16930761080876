import React from 'react'

export default function AbouHero() {
  return (
    <div>
     <section className="banner-section inner-banner privacy-content">
  <div className="overlay">
    <div className="banner-content d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-start">
          <div className="col-lg-8 col-md-12">
            <div className="main-content">
              <h5 className='text-white'>Our aim to offer simpler and faster crypto payment gateway for our users.
              </h5>
              {/* <a href="#" class="cmn-btn mt-4">Join Now </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    </div>
  )
}
