import React from "react";

export default function FaqMain() {
  return (
    <div>
      <section className="faqs-section main">
        <div className="overlay pt-120 pb-120">
          <div className="container wow fadeInUp">
            <ul
              className="nav nav-tabs justify-content-center"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="account-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#account"
                  type="button"
                  role="tab"
                  aria-controls="account"
                  aria-selected="true"
                >
                  DollarPay
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="affiliates-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#affiliates"
                  type="button"
                  role="tab"
                  aria-controls="affiliates"
                  aria-selected="false"
                >
                  API Services
                </button>
              </li>
          
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="account"
                role="tabpanel"
                aria-labelledby="account-tab"
              >
                <p className="mt-4 text-center">
                  DollarPay is a payment gateway for processing crypto payments.
                </p>
                <div className="row wrapper">
                  <div className="col-lg-6">
                    <div
                      className="accordion accordion-account"
                      id="accordionAccount"
                    >
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="account-headingOne"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#account-collapseOne"
                            aria-expanded="false"
                            aria-controls="account-collapseOne"
                          >
                            What is Dollar Pay?
                          </button>
                        </h6>
                        <div
                          id="account-collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="account-headingOne"
                          data-bs-parent="#accordionAccount"
                        >
                          <div className="accordion-body">
                            <p>
                              DollarPay is a global cryptocurrencies payment
                              process/gateway for online cryptocurrency payment
                              solutions. Read more about us, Click Here
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="account-headingTwo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#account-collapseTwo"
                            aria-expanded="false"
                            aria-controls="account-collapseTwo"
                          >
                            What services does DollarPay provide?
                          </button>
                        </h6>
                        <div
                          id="account-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="account-headingTwo"
                          data-bs-parent="#accordionAccount"
                        >
                          <div className="accordion-body">
                            <p>
                              DollarPay has most of the crypto payment services
                              available on the website so that you don’t have to
                              go anywhere except staying with us.
                            </p>
                            <p>
                              We have the functionalities like payment gateway
                              integration, coin listing, digital wallet, digital
                              invoices, Plugin, rest APIs, etc.{" "}
                            </p>
                            <p>
                              All of these services are simple and quick so that
                              anyone can set up easily and start doing business
                              and accepting crypto coins within a minute.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="account-headingThree"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#account-collapseThree"
                            aria-expanded="false"
                            aria-controls="account-collapseThree"
                          >
                            Do you support any fiat currency or credit card
                            services?
                          </button>
                        </h6>
                        <div
                          id="account-collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="account-headingThree"
                          data-bs-parent="#accordionAccount"
                        >
                          <div className="accordion-body">
                            <p>
                              No, we are not supporting any fiat currency or any
                              credit card service.Conversely, you can convert
                              your cryptocoins later into fiat currency by using
                              other exchanges. We support Tether USDT Coin
                            </p>
                            <p>
                              Coinremitter only allows to send and receive
                              payment in crypto coins and other functionalities
                              will only support in crypto coins but not in fiat
                              currency.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="account-headingFour"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#account-collapseFour"
                            aria-expanded="false"
                            aria-controls="account-collapseFour"
                          >
                            What are the benefits for accepting crypto payments?
                          </button>
                        </h6>
                        <div
                          id="account-collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="account-headingFour"
                          data-bs-parent="#accordionAccount"
                        >
                          <div className="accordion-body">
                            <p>
                              Since cryptocurrency has shown its miraculous
                              performance in the market, All the major firms are
                              willing to accept payments in cryptocurrency. Some
                              of the major companies have already started
                              accepting payments in cryptocurrency.
                            </p>
                            <p>
                              Accepting crypto technology will open the gateways
                              for those who want to spread their knowledge
                              across the globe but are afraid of high foreign
                              exchanges. Well, it doesn’t apply on the crypto
                              market however, you can be anonymous for the rest
                              of the world even for your vendors. Peer 2 Peer
                              connection built the strong connection between
                              vendor and merchant. Moreover, it will gain more
                              trust between them and can directly send coins
                              into the merchant’s wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="accordion accordion-account"
                      id="accordionSecondAcc"
                    >
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="account-headingFive"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#account-collapseFive"
                            aria-expanded="false"
                            aria-controls="account-collapseFive"
                          >
                            Do I need any ID and KYC to verify my account?
                          </button>
                        </h6>
                        <div
                          id="account-collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="account-headingFive"
                          data-bs-parent="#accordionAccount"
                        >
                          <div className="accordion-body">
                            <p>
                              No, you don’t have to submit your ID and KYC while
                              making an account on DollarPay. It only requires
                              your email id or phone number(optional) that’s it.
                              But you have to verify your email id in order to
                              confirm that it’s actually you or someone else.
                              This is the only thing you have to do to become a
                              member of DollarPay. If you haven’t signed up yet
                              then signup for free and become a member of our
                              family.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="secondAccheadingOne"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#secondAcccollapseOne"
                            aria-expanded="false"
                            aria-controls="secondAcccollapseOne"
                          >
                            What are the charges?
                          </button>
                        </h6>
                        <div
                          id="secondAcccollapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="secondAccheadingOne"
                          data-bs-parent="#accordionSecondAcc"
                        >
                          <div className="accordion-body">
                            <p>
                              Our transaction fees are lowest in this
                              cryptocurrencies payment process industry, Its
                              0.23% on withdrawal of the currencies. We are
                              proud to offer the lowest processing fees.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="secondAccheadingTwo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#secondAcccollapseTwo"
                            aria-expanded="false"
                            aria-controls="secondAcccollapseTwo"
                          >
                            Does DollarPay have any affiliate program?
                          </button>
                        </h6>
                        <div
                          id="secondAcccollapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="secondAccheadingTwo"
                          data-bs-parent="#accordionSecondAcc"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes! Every merchant has a unique referral link
                              which they can share. DollarPay affiliates program
                              can earn you up to 75% commission from the
                              DollarPay revenue (Transaction fees) after
                              successful transactions completed by customers who
                              are directed to DollarPay through their website.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="affiliates"
                role="tabpanel"
                aria-labelledby="affiliates-tab"
              >
                <p className="mt-4 text-center">
                  API services for integration process.
                </p>
                <div className="row wrapper">
                  <div className="col-lg-6">
                    <div
                      className="accordion accordion-Affiliates"
                      id="accordionAffiliates"
                    >
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="Affiliates-headingOne"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#Affiliates-collapseOne"
                            aria-expanded="false"
                            aria-controls="Affiliates-collapseOne"
                          >
                            Do you have any plugins?
                          </button>
                        </h6>
                        <div
                          id="Affiliates-collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="Affiliates-headingOne"
                          data-bs-parent="#accordionAffiliates"
                        >
                          <div className="accordion-body">
                            <p>Yes! We do have our plugin on the websites.</p>
                            <p>
                              We have successfully integrated with Wordpress
                              Laravel and PHP. you can easily find our plugin
                              into these websites and you can also install it by
                              using our rest API.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="Affiliates-headingTwo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#Affiliates-collapseTwo"
                            aria-expanded="false"
                            aria-controls="Affiliates-collapseTwo"
                          >
                            Can I use your rest API?
                          </button>
                        </h6>
                        <div
                          id="Affiliates-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="Affiliates-headingTwo"
                          data-bs-parent="#accordionAffiliates"
                        >
                          <div className="accordion-body">
                            <p>
                              Of course you can. It's even mandatory to use our
                              API if you’ve installed our plugin into your
                              website.
                            </p>
                            <p>
                              You can check out our API document page to start
                              your integration. If you are already using our
                              rest API. API key is the most important thing
                              without this you can’t even operate our plugin in
                              your website.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="Affiliates-headingThree"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#Affiliates-collapseThree"
                            aria-expanded="false"
                            aria-controls="Affiliates-collapseThree"
                          >
                            How can I get my API key?
                          </button>
                        </h6>
                        <div
                          id="Affiliates-collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="Affiliates-headingThree"
                          data-bs-parent="#accordionAffiliates"
                        >
                          <div className="accordion-body">
                            <p>
                              Once you’ve successfully created your wallet on
                              the DollarPay website then click on your wallet.
                            </p>
                            <p>
                              Once you click on your wallet a new tab will open
                              and find the credential tab.
                            </p>
                            <p>
                              Click on this tab where you will see the API key.
                            </p>
                            <p>
                              Click on an API key and it will be automatically
                              copied.
                            </p>
                            <p>Now start your integration process.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="Affiliates-headingFour"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#Affiliates-collapseFour"
                            aria-expanded="false"
                            aria-controls="Affiliates-collapseFour"
                          >
                            What would happen if I lose or forget my API keys?
                          </button>
                        </h6>
                        <div
                          id="Affiliates-collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="Affiliates-headingFour"
                          data-bs-parent="#accordionAffiliates"
                        >
                          <div className="accordion-body">
                            <p>
                              It’s not that big issue, if you forget your
                              password then you just have to do one thing go to
                              the Wallet Page on *click on your wallet, after
                              that you will find 5 tabs you must *click on
                              Credential in this tab you will see the password
                              option. Click on the right side of the pencil icon
                              and a new box will appear.
                            </p>
                            <p>
                              On that box you can create your new password
                              successfully. Process is not that hard, you can
                              even do it simply. Make sure you don’t lose your
                              new password again.
                            </p>
                            <p>
                              Account and Wallet Services <br />
                              Account and wallet is for existing and new members
                              of DollarPay.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="Affiliates-headingFive"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#Affiliates-collapseFive"
                            aria-expanded="false"
                            aria-controls="Affiliates-collapseFive"
                          >
                            Do I need to create an account to use your services?
                          </button>
                        </h6>
                        <div
                          id="Affiliates-collapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="Affiliates-headingFive"
                          data-bs-parent="#accordionAffiliates"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes. In order to use DollarPay, you must open an
                              account. The process is standard: You will need to
                              provide contact information (Email address and
                              Name). It does not require to complete KYC.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="SecondAffheadingOne"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SecondAffcollapseOne"
                            aria-expanded="false"
                            aria-controls="SecondAffcollapseOne"
                          >
                            Can I create more than one wallet?
                          </button>
                        </h6>
                        <div
                          id="SecondAffcollapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="SecondAffheadingOne"
                          data-bs-parent="#accordionSecondAff"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes! You can make multiple digital wallets of the
                              same coin in order to send and receive payments.
                              This feature will be useful if you are managing
                              two+ websites or apps. Mostly it is useful for
                              developers.
                            </p>
                            <p>Make sure you keep two points in your mind:</p>
                            <ul style={{ listStyle: "disc" }}>
                              <li>
                                Make a wallet as per your and your customer's
                                requirement.
                              </li>
                              <li>
                                Do not make a wallet for fun, if you really want
                                to give wings to your business then we are
                                welcoming you with kind heart.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="accordion accordion-Affiliates"
                      id="accordionSecondAff"
                    >
              
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="SecondAffheadingTwo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SecondAffcollapseTwo"
                            aria-expanded="false"
                            aria-controls="SecondAffcollapseTwo"
                          >
                            How do I delete my account?
                          </button>
                        </h6>
                        <div
                          id="SecondAffcollapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="SecondAffheadingTwo"
                          data-bs-parent="#accordionSecondAff"
                        >
                          <div className="accordion-body">
                            <p>
                              If you really want to delete your wallet then,
                              click on the wallet you wanted to delete.
                            </p>
                            <p>
                              {" "}
                              <p className="mt-2">
                                Let’s say if you have 2 Bitcoin’s wallet and you
                                want to delete one of them. Then proceed on this
                                information, click on the bitcoin wallet. Now
                                new tab will open on your desktop, over the 5
                                options you must *click on the General tab.
                                Scroll down and at the bottom of the page you
                                will find the Delete Wallet tab. *click on it.
                                And after that *click on Confirm. Now you’ve
                                successfully deleted your wallet
                              </p>
                              <p className="mt-2">
                                (Note: Before you delete your wallet you must
                                check out your wallet balance because it will
                                not be recovered once you delete your wallet the
                                remaining fund will be gone)
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="SecondAffheadingThree"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SecondAffcollapseThree"
                            aria-expanded="false"
                            aria-controls="SecondAffcollapseThree"
                          >
                            How does DollarPay protect against scammers?
                          </button>
                        </h6>
                        <div
                          id="SecondAffcollapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="SecondAffheadingThree"
                          data-bs-parent="#accordionSecondAff"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes! We do have the best security service (2FA) to
                              protect our users' private data and transactions
                              history and also other things which our users hold
                              in their blockchains. To protect your blockchain
                              address & key we must tell our users to use a
                              strong Password and do not lose under any
                              circumstances.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="SecondAffheadingFour"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SecondAffcollapseFour"
                            aria-expanded="false"
                            aria-controls="SecondAffcollapseFour"
                          >
                            What is auto-withdrawal?
                          </button>
                        </h6>
                        <div
                          id="SecondAffcollapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="SecondAffheadingFour"
                          data-bs-parent="#accordionSecondAff"
                        >
                          <div className="accordion-body">
                            <p>
                              The auto-withdrawal functionality by DollarPay
                              operates every 30 minutes, however, users with
                              free membership cannot stop it. While premium
                              membership users can disable auto-withdrawal
                              functionality for all coins/tokens except (USDT
                              ERC20, BNB, ETH).
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="SecondAffheadingFive"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SecondAffcollapseFive"
                            aria-expanded="false"
                            aria-controls="SecondAffcollapseFive"
                          >
                            How do I create and send invoices?
                          </button>
                        </h6>
                        <div
                          id="SecondAffcollapseFive"
                          className="accordion-collapse collapse"
                          aria-labelledby="SecondAffheadingFive"
                          data-bs-parent="#accordionSecondAff"
                        >
                          <div className="accordion-body">
                            <p>
                              If you are a merchant and want to send an Invoice
                              to your vendor then, it’s a simple functionality.
                              On the dashboard page you will find an Invoice tab
                              just beneath the Referral tab. *click on the
                              Invoice tab. On the Invoice page you will see New
                              tab on the right side at the top *click on it. New
                              page will open where you find several options to
                              fill it appropriately. and send it to your vendor.
                              Another option is, You can create invoices using
                              our API.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="SecondAffheadingsix"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SecondAffcollapsesix"
                            aria-expanded="false"
                            aria-controls="SecondAffcollapsesix"
                          >
                            Do you offer wallet testing services?
                          </button>
                        </h6>
                        <div
                          id="SecondAffcollapsesix"
                          className="accordion-collapse collapse"
                          aria-labelledby="SecondAffheadingsix"
                          data-bs-parent="#accordionSecondAff"
                        >
                          <div className="accordion-body">
                            <p>
                              Yes! We do offer our users to use our TEST COIN
                              WALLET service to practice in the live environment
                              of DollarPay. It works exactly like the real
                              wallet which means the beginners get to know how
                              this wallet system works and how to secure the
                              account and address of their cryptocoins. We also
                              offer free coins in the wallet once they
                              successfully created a test coin wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h6
                          className="accordion-header"
                          id="SecondAffheadingsevan"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SecondAffcollapsesevan"
                            aria-expanded="false"
                            aria-controls="SecondAffcollapsesevan"
                          >
                            What should I do if I don't have access to my Google
                            2FA?
                          </button>
                        </h6>
                        <div
                          id="SecondAffcollapsesevan"
                          className="accordion-collapse collapse"
                          aria-labelledby="SecondAffheadingsevan"
                          data-bs-parent="#accordionSecondAff"
                        >
                          <div className="accordion-body">
                            <p>
                              If you don’t have access to your google 2fa then
                              use Code card security. It’s basically a program
                              that will enable your 2fa security from disable
                              mode.
                              <br />
                              After enabling it you will have to enter some
                              appropriate things and after filling it all you
                              will receive a codeguard token and fill in the
                              confirmation tab and submit it.
                              <br />
                              With this process you will get access to your 2FA.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
