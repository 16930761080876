import React from 'react'

export default function NewsMedia() {
  return (
    <div>
        <section className="supported-countries">
  <div className="overlay pb-90 pt-90">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="main-content text-center">
            <h4 className='mb-2'>Find us in news & media</h4>
            <p>Read about us and know more from reputed platforms.</p>
            <ul>
              <li className='p-2'><img src="assets/img/new-img/partners-1.png" className='h-90px' alt="" /></li>
              <li className='p-2'><img src="assets/img/new-img/partners-5.png" className='h-90px'  alt="" /></li>
              <li className='p-2'><img src="assets/img/new-img/partners-2.png" className='h-90px'  alt="" /></li>
              <li className='p-2'><img src="assets/img/new-img/partners-6.png" className='h-90px'  alt="" /></li>
              <li className='p-2'><img src="assets/img/new-img/partners-3.png" className='h-90px'  alt="" /></li>
              <li className='p-2'><img src="assets/img/new-img/partners-7.png" className='h-90px'  alt="" /></li>
              <li className='p-2'><img src="assets/img/new-img/partners-4.png" className='h-90px'  alt="" /></li>
              <li className='p-2'><img src="assets/img/new-img/partners-8.png" className='h-90px'  alt="" /></li>
         
            
            </ul>
          
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}
