import React from "react";

export default function SecureMoney() {
  return (
    <div>
      <section className="security-section">
        <div className="overlay pt-100 pb-100">
          <div className="container cus-mar">
            <div className="row">
              <div className="col-lg-6 col-md-8 d-flex align-items-center">
                <div className="left-area">
                  <div className="section-header">
                    <h4 className="title">
                      Support for Wide Range of{" "}
                      <span className="text-Purple">Cryptocurrencies</span>
                    </h4>
                    <p>
                      Businesses & merchants can choose to accept a wide range
                      of crypto assets as well as custom currencies with native
                      integration.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap gap-2 justify-content-center">
                    <img
                      src="assets/img/new-img/network_1.png"
                      className="img-tr-to"
                      alt="icon"
                    />

                    <img
                      src="assets/img/new-img/btc.png"
                      className="img-tr-to"
                      alt="icon"
                    />
                    <img
                      src="assets/img/new-img/litecoin.png"
                      className="img-tr-to"
                      alt="icon"
                    />
                    <img
                      src="assets/img/new-img/btc_cash.png"
                      className="img-tr-to"
                      alt="icon"
                    />
                    <img
                      src="assets/img/new-img/beth.png"
                      className="img-tr-to"
                      alt="icon"
                    />
                    <img
                      src="assets/img/new-img/dogecoin.png"
                      className="img-tr-to"
                      alt="icon"
                    />
                    <img
                      src="assets/img/new-img/more.png"
                      className="img-tr-to"
                      alt="icon"
                    />
                  </div>
                  <a href="/coins" class="cmn-btn mt-4">
                    View All
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div
                  className="img-area wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <img src="assets/img/new-img/support-for-wide.png" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
