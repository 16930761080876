import React from "react";

export default function Integration() {
  return (
    <div>
      <section
        className="features-section "
        style={{ background: "lightgrey" }}
      >
        <div className="overlay pt-130  pb-50">
          <div
            className="container wow fadeInUp "
            style={{ visibility: "visible", animationName: "fadeInUp" }}
          >
            <div className="row justify-content-center text-center">
              <div className="col-lg-8">
                <div className="section-header">
                  <h4 className="title">DollarPay <span className="text-Purple"> Integration</span></h4>
                  <p>
                    DollarPay provides plugins for all major E-Commerce
                    platforms to make the process of accepting Bitcoin, and
                    cryptocurrency as payment, easy, fast and seamless. With its
                    free-to-use plugins and quick integration process which is
                    perfect for any online business to join the crypto-world and
                    gain a global market.
                  </p>
                  {/* <a href="#" className="cmn-btn mt-4">
                    View All
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
