import React from "react";

export default function CoinsHero() {
  return (
    <div>
      <section className="privacy-content terms">
        <div className="overlay pb-120 pt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="top-wrapper">
                  <div className="safe-data">
                    <h4>List of All the Supported Cryptocurrencies</h4>
                    <p>
                      Check the list of crypto coins supported by DollarPay
                      having high market caps and large communities. Merchants
                      on DollarPay can accept crypto payments by creating a
                      compatible wallet for supported cryptocurrencies.
                    </p>
                    <p className="mt-2">
                      We can see cryptocurrency payments replacing fiat payments
                      in future, maybe or maybe not. DollarPay’s cryptocurrency
                      list just aims to provide more convenience to merchants in
                      accepting crypto payments.
                    </p>
                    <p className="mt-2">
                      DollarPay’s crypto list is specially curated by experts
                      that cover all the aspects that can benefit merchants.
                      Merchants are probably going to find a large number of
                      customers willing to pay in cryptocurrencies supported by
                      DollarPay.
                    </p>
                    <p className="mt-2">
                      Each of these cryptocurrencies have its advantages such as Network1's efficiency,
                      Bitcoin’s largest market cap, Litecoin’s rapidly growing
                      adoption, Bitcoin cash’s higher block size, Ethereum’s
                      largest ecosystem in blockchain & cryptocurrency,
                      Dogecoin’s rapidly growing popularity & network, Dash’s
                      faster crypto transactions, Tether’s stability, Binance’s
                      liquidity, Tron’s incredibly low transaction fees and
                      Monero’s untraceable transaction history.
                    </p>
                    <p className="mt-2">
                      These are just highlights, all these cryptocurrencies have
                      a bunch of advantages that have the potential to benefit
                      merchants in future.
                    </p>
                    <p className="mt-2">
                      Besides, you’ll find something unique in this crypto coin
                      list which is the Test Coin. It behaves just like a
                      conventional cryptocurrency that Merchants can use to test
                      their integrations.
                    </p>
                    {/* <a href="#" class="cmn-btn mb-3" style={{width:"200px"}}>
                      Start Accepting
                    </a> */}
                  </div>
                  <p className="mt-3">
                    Note: Currently, we don’t offer fiat currencies (e.g. USD,
                    EUR, JPY, INR etc.)
                  </p>
                  <p className="'mt-2">
                    You can check the list of supported coins below. However,
                    this list may be expanded in future. Stay tuned for more
                    updates.
                  </p>
                  <div className="row ps-2">
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img
                          src="assets/img/new-img/network_1.png"
                          alt="icon"
                        />
                      </a>
                      <div className="img-br-ri">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img src="assets/img/new-img/btc.png" alt="icon" />
                      </a>
                      <div className="img-br-ri">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img
                          src="assets/img/new-img/litecoin.png"
                          alt="icon"
                        />{" "}
                      </a>
                      <div className="img-br-ri">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img src="assets/img/new-img/btc_cash.png" alt="icon" />
                      </a>
                      <div className="img-br-ri">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img src="assets/img/new-img/beth.png" alt="icon" />
                      </a>
                      <div className="img-br-ri">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img src="assets/img/new-img/dogecoin.png" alt="icon" />
                      </a>
                      <div className="img-br-ri">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="row ps-2">
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <div className="img-br-rive">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img
                          src="assets/img/new-img/network_1.png"
                          alt="icon"
                        />
                      </a>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <div className="img-br-rive">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img src="assets/img/new-img/btc.png" alt="icon" />
                      </a>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <div className="img-br-rive">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img
                          src="assets/img/new-img/litecoin.png"
                          alt="icon"
                        />{" "}
                      </a>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <div className="img-br-rive">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img src="assets/img/new-img/btc_cash.png" alt="icon" />
                      </a>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <div className="img-br-rive">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img src="assets/img/new-img/beth.png" alt="icon" />
                      </a>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-flex my-4 my-sm4 my-md-3 my-lg-3 my-xl-2">
                      <div className="img-br-rive">
                        <img src="assets/img/new-img/ltc-line.png" alt="" />
                      </div>
                      <a href="#" className="rote-bg-coi">
                        {" "}
                        <img src="assets/img/new-img/dogecoin.png" alt="icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
