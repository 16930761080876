import React from "react";

export default function Source() {
  return (
    <div>
      <section className="affiliate-section">
        <div
          className="overlay pt-100 pb-100"
          // style={{ background: "lightgrey" }}
        >
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-lg-5 d-flex justify-content-center">
                <div className="left-area">
                  <div className="d-flex flex-wrap gap-2">
                    <div class="single-item text-center">
                      <div class="icon">
                        <img
                          src="assets/img/new-img/magento_2.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div class="single-item text-center">
                      <div class="icon">
                        <img
                          src="assets/img/new-img/wordpress.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div class="single-item text-center">
                      <div class="icon">
                        <img
                          src="assets/img/new-img/openchart.png"
                          alt="icon"
                        />
                      </div>
                    </div>

                    <div class="single-item text-center">
                      <div class="icon">
                        <img
                          src="assets/img/new-img/prestashop.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap gap-2 justify-content-center ">
                    <div class="single-item text-center">
                      <div class="icon">
                        <img src="assets/img/new-img/laravel.png" alt="icon" />
                      </div>
                    </div>
                    <div class="single-item text-center">
                      <div class="icon">
                        <img src="assets/img/new-img/php.png" alt="icon" />
                      </div>
                    </div>
                    <div class="single-item text-center">
                      <div class="icon">
                        <img src="assets/img/new-img/node.png" alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <div className="section">
                  <h4 className="title text-black">
                    Integrate Our{" "}
                    <span className="text-Purple">Plugins & APIs</span>
                  </h4>
                  <p>
                    Enable crypto payment service by integrating our web-plugins
                    or APIs. All of our plugins are accessible on multiple
                    web-panels including Wordpress, Opencart, Magento, Laravel,
                    and others.
                  </p>
                  <a href="/plugins" className="cmn-btn mt-3">
                    See more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
