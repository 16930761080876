import React from "react";

export default function FeesMain() {
  return (
    <div>
      <section className="dashboard-section transactions recipients p-0 m-0 w-100">
        <div className="overlay  pb-90">
          <div className="container">
            <div className="row pt-100">
              <div className="col-xl-12">
                <div class="section-header text-center">
                  <h4 class="title text-cener">
                    {" Comparison "}
                    <span class="text-Purple"> Table</span>
                  </h4>
                </div>
                <div className="transactions-main">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col" className="text-start" style={{ width: "14%" }}>
                           <p className="mdr text-black text-start"> Features{" "}</p>
                          </th>
                          <th scope="col" style={{ width: "14%" }}>
                            <img
                              src="assets/img/new-img/logo.svg"
                              className="logo"
                              alt="logo"
                              style={{ width: "100%" }}
                            />
                          </th>
                          <th scope="col" style={{ width: "14%" }}>
                            <img
                              src="assets/img/table-icon/opennode-1.png"
                              className="logo"
                              alt="opennode"
                              style={{ width: "100%" }}
                            />
                          </th>
                          <th scope="col" style={{ width: "14%" }}>
                            <img
                              src="assets/img/table-icon/spicepay-1.png"
                              className="logo"
                              alt="spicepay"
                              style={{ width: "100%" }}
                            />
                          </th>
                          <th scope="col" style={{ width: "14%" }}>
                            <img
                              src="assets/img/table-icon/spicepay-1.png"
                              className="logo"
                              alt="spicepay"
                              style={{ width: "100%" }}
                            />
                          </th>
                          <th scope="col" style={{ width: "14%" }}>
                            <img
                              src="assets/img/table-icon/spectrocoin-1.png"
                              className="logo"
                              alt="spectrocoin"
                              style={{ width: "100%" }}
                            />
                          </th>
                          <th scope="col" style={{ width: "14%" }}>
                            <img
                              src="assets/img/table-icon/blockonomics-1.png"
                              className="logo"
                              alt="Blockonomics"
                              style={{ width: "100%" }}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-start border">
                          <th className="px-1">
                            <p className="mdr">Fees </p>
                          </th>
                          <td>
                            <p className="mdr">0.23% </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">1%</p>
                          </td>
                          <td className="">
                            <p className="mdr">Network fee* + 0.99% </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">1%</p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">2%-5%</p>
                          </td>
                          <td>
                            <p className="mdr">2%</p>
                          </td>
                        </tr>
                        <tr
                          className="text-start border"
                          style={{ background: "#f7f7f7" }}
                        >
                          <th className="px-1">
                            <p className="mdr">Supported Coins </p>
                          </th>
                          <td>
                            <p className="mdr">Bitcoin</p>
                            <p className="mdr">Bitcoin Cash</p>
                            <p className="mdr">Ethereum</p>
                            <p className="mdr">Litecoin</p>
                            <p className="mdr">DogeCoin</p>
                            <p className="mdr">Binance Coin</p>
                            <p className="mdr">Dash</p>
                            <p className="mdr">Tether USD</p>
                            <p className="mdr">ERC20 </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">Bitcoin</p>
                          </td>
                          <td className="">
                            <p className="mdr">Bitcoin</p>
                            <p className="mdr">Ethereum</p>
                            <p className="mdr">Litecoin</p>
                            <p className="mdr">Bitcoin Cash</p>
                            <p className="mdr">Litecoin</p>
                            <p className="mdr">XRP</p>
                            <p className="mdr">Dash</p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">Bitcoin</p>
                            <p className="mdr">Ethereum</p>
                            <p className="mdr">Litecoin</p>
                            <p className="mdr">Bitcoin Cash</p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">Bitcoin</p>
                            <p className="mdr">Ethereum</p>
                            <p className="mdr">Dash</p>
                            <p className="mdr">NEM</p>
                            <p className="mdr">
                              {" "}
                              <p className="mdr">Litecoin</p>
                            </p>
                            <p className="mdr">Stellar Lumens</p>
                            <p className="mdr">Ripple</p>
                            <p className="mdr">And More</p>
                          </td>
                          <td>
                            <p className="mdr">Bitcoin</p>
                            <p className="mdr">Bitcoin Cash</p>
                          </td>
                        </tr>
                        <tr className="text-start border">
                          <th className="px-1">
                            <p className="mdr">KYC </p>
                          </th>
                          <td>
                            <p className="mdr">No </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">Yes</p>
                          </td>
                          <td className="">
                            <p className="mdr">Yes </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">Yes</p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">Yes</p>
                          </td>
                          <td>
                            <p className="mdr">Yes</p>
                          </td>
                        </tr>
                        <tr
                          className="text-start border"
                          style={{ background: "#f7f7f7" }}
                        >
                          <th className="px-1">
                            <p className="mdr">Supported Plugins </p>
                          </th>
                          <td>
                            <p className="mdr">WordPress</p>
                            <p className="mdr">Magneto 2</p>
                            <p className="mdr">Ethereum</p>
                            <p className="mdr">Laravel</p>
                            <p className="mdr">PHP</p>
                            <p className="mdr">Prestashop</p>
                            <p className="mdr">Rest Api </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">BigCommerce</p>
                            <p className="mdr">Wordpress</p>
                            <p className="mdr">Prestashop</p>
                            <p className="mdr">Magneto</p>
                            <p className="mdr">Opencart</p>
                            <p className="mdr">Shopify</p>
                          </td>
                          <td className="">
                            <p className="mdr">wordpress</p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">Magento</p>
                            <p className="mdr">WooCommerce</p>
                            <p className="mdr">WordPress</p>
                            <p className="mdr">Opencart</p>
                            <p className="mdr">Zencart</p>
                            <p className="mdr">Prestashop</p>
                            <p className="mdr">WHMCS</p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">WooCommerce</p>
                            <p className="mdr">Magento</p>
                            <p className="mdr">PrestaShop</p>
                            <p className="mdr">OpenCart</p>
                            <p className="mdr">
                              {" "}
                              <p className="mdr">Drupal</p>
                            </p>
                            <p className="mdr">WHMCS</p>
                            <p className="mdr">ZenCart</p>
                          </td>
                          <td>
                            <p className="mdr">WHMCS</p>
                            <p className="mdr">Wordpress</p>
                            <p className="mdr">Prestashop</p>
                            <p className="mdr">Opencart</p>
                            <p className="mdr">Wix</p>
                            <p className="mdr">SquareSpace</p>
                            <p className="mdr">Shopify</p>
                          </td>
                        </tr>
                        <tr className="text-start border">
                          <th className="px-1">
                            <p className="mdr">Setup Charge </p>
                          </th>
                          <td>
                            <p className="mdr">No </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">No</p>
                          </td>
                          <td className="">
                            <p className="mdr">No </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">No</p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">No</p>
                          </td>
                          <td>
                            <p className="mdr">No</p>
                          </td>
                        </tr>
                        <tr
                          className="text-start border"
                          style={{ background: "#f7f7f7" }}
                        >
                          <th className="px-1">
                            <p className="mdr">Security </p>
                          </th>
                          <td>
                            <p className="mdr">2FA</p>
                            <p className="mdr">Code Card,</p>
                            <p className="mdr">Email Verification </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">2FA</p>
                          </td>
                          <td className="">
                            <p className="mdr">2FA</p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">-- </p>
                          </td>
                          <td>
                            {/* <p>1%</p> */}
                            <p className="mdr">-- </p>
                          </td>
                          <td>
                            <p className="mdr">Email</p>
                            <p className="mdr">Verification</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center mt-3">
                <a href="#" class="cmn-btn mx-1 me-0 me-sm-2 mt-2">
                Get Started
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
