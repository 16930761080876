import React from "react";

export default function AddToken() {
  return (
    <div>
      <section className="affiliate-section">
        <div
          className="overlay pt-100 pb-100"
          // style={{ background: "lightgrey" }}
        >
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-lg-5 d-flex justify-content-center">
                    <img
                      src="./assets/img/new-img/tokenomic.svg"
                      alt="image"
                    />
                  {/* <div className="lef">
                  </div> */}
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <div className="section">
                  <h4 className="title text-black">
                    DollarPay &nbsp;
                    <span className="text-Purple"> Token </span>
                  </h4>
                  <p>
                    DollarPay payment platform has a native token DP which is
                    used as an option for payments across multiple channels and
                    our ecosystem partners.
                  </p>
                  <div className="d-flex mt-3">
                    <h6>Token Name: &nbsp; </h6>
                    <p> DollaryPay</p>
                  </div>
                  <div className="d-flex ">
                    <h6>Ticker : &nbsp; </h6>
                    <p> DP</p>
                  </div>
                  <div className="d-flex ">
                    <h6>supply : &nbsp; </h6>
                    <p> 510,000,000</p>
                  </div>
                  <div className="d-flex ">
                    <h6>Decimal : &nbsp; </h6>
                    <p> 18</p>
                  </div>
                  <div className="d-flex ">
                    <h6>Chain : &nbsp; </h6>
                    <p> Network1</p>
                  </div>
                  <div className="d-flex ">
                    <h6>Token Standard : &nbsp; </h6>
                    <p> NET120</p>
                  </div>
                  <a href="#" className="cmn-btn mt-3">
                    Buy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
