import React from 'react'
import Header from '../Coman/Header'
import RiskPolicyMain from './RiskPolicyMain'

import Footer from '../Coman/Footer'

export default function RiskPolicy() {
  return (
    <div>
        <Header/>
        <RiskPolicyMain/>
        <Footer/>
    </div>
  )
}
