import React from 'react'
import Sidebar from '../Components/Coman/Sidebar'
import Header2 from '../Components/Coman/Header2'
import DashboardMain from './DashboardMain'
// import Cashback from './Cashback'

export default function Dashboard() {
  return (
    <>
      <Sidebar />
      <DashboardMain />
    </>
  )
}
