import React from 'react'
import Sidebar from '../../Components/Coman/Sidebar'

export default function WithdrawalList() {
    return (
        <>
            <Sidebar />
            <section className="dashboard-section body-collapse transactions">
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="head-area">
                            <div className="row">
                                <div className="col-lg-5 col-md-4">
                                    <h4>Withdrawal History</h4>
                                </div>
                                <div className="col-lg-7 col-md-8">
                                    <div className="transactions-right d-flex align-items-center">
                                        <form action="#" className="flex-fill">
                                            <div className="form-group d-flex align-items-center">
                                                <img src="assets-new/images/icon/search.png" alt="icon" />
                                                <input type="text" placeholder="Type to search..." />
                                            </div>
                                        </form>
                                        <a href="javascript:void(0)">Monthly Statement</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="transactions-main">
                                    <div className="top-items">
                                        <h6>All Transactions</h6>
                                    </div>
                                    <div className="filters-item">
                                        <div className="single-item">
                                            <select>
                                                <option value={1}>23 Nov 2021- 21 Feb 2022</option>
                                                <option value={2}>23 Feb 2021- 21 Mar 2022</option>
                                                <option value={3}>23 Mar 2021- 21 Apr 2022</option>
                                            </select>
                                        </div>
                                        <div className="single-item">
                                            <select>
                                                <option value={1}>Balance</option>
                                                <option value={2}>Balance</option>
                                                <option value={3}>Balance</option>
                                            </select>
                                        </div>
                                        <div className="single-item">
                                            <select>
                                                <option>All Filters</option>
                                                <option value={1}>Filters 1</option>
                                                <option value={2}>Filters 2</option>
                                                <option value={3}>Filters 3</option>
                                            </select>
                                        </div>
                                        <div className="single-item">
                                            <button>Clear Filters</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name/ Business</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr data-bs-toggle="modal" data-bs-target="#transactionsMod">
                                                    <th scope="row">
                                                        <p>Bangkok Bank</p>
                                                        <p className="mdr">Withdraw to bank account</p>
                                                    </th>
                                                    <td>
                                                        <p>03:00 PM</p>
                                                        <p className="mdr">10 Mar 2022</p>
                                                    </td>
                                                    <td>
                                                        <p className="inprogress">In Progress</p>
                                                    </td>
                                                    <td>
                                                        <p>-$520</p>
                                                        <p className="mdr">$3.0</p>
                                                    </td>
                                                </tr>
                                                <tr data-bs-toggle="modal" data-bs-target="#transactionsMod">
                                                    <th scope="row">
                                                        <p>Envato Pty Ltd</p>
                                                        <p className="mdr">Marketplace Payment Received</p>
                                                    </th>
                                                    <td>
                                                        <p>04:30 PM</p>
                                                        <p className="mdr">01 Mar 2022</p>
                                                    </td>
                                                    <td>
                                                        <p className="completed">Completed</p>
                                                    </td>
                                                    <td>
                                                        <p>+$450</p>
                                                        <p className="mdr">No Fees</p>
                                                    </td>
                                                </tr>
                                                <tr data-bs-toggle="modal" data-bs-target="#transactionsMod">
                                                    <th scope="row">
                                                        <p>Mailchimp</p>
                                                        <p className="mdr">Subscription Service Payment</p>
                                                    </th>
                                                    <td>
                                                        <p>01:15 PM</p>
                                                        <p className="mdr">25 Mar 2022</p>
                                                    </td>
                                                    <td>
                                                        <p className="completed">Completed</p>
                                                    </td>
                                                    <td>
                                                        <p>-$100</p>
                                                        <p className="mdr">No Fees</p>
                                                    </td>
                                                </tr>
                                                <tr data-bs-toggle="modal" data-bs-target="#transactionsMod">
                                                    <th scope="row">
                                                        <p>Facebook Ads</p>
                                                        <p className="mdr">Ads Service</p>
                                                    </th>
                                                    <td>
                                                        <p>07:05 PM</p>
                                                        <p className="mdr">15 Mar 2022</p>
                                                    </td>
                                                    <td>
                                                        <p className="pending">Pending</p>
                                                    </td>
                                                    <td>
                                                        <p>$200</p>
                                                        <p className="mdr">No Fees</p>
                                                    </td>
                                                </tr>
                                                <tr data-bs-toggle="modal" data-bs-target="#transactionsMod">
                                                    <th scope="row">
                                                        <p>Upwork Escow Inc</p>
                                                        <p className="mdr">Payment payment</p>
                                                    </th>
                                                    <td>
                                                        <p>04:02 PM</p>
                                                        <p className="mdr">10 Mar 2022</p>
                                                    </td>
                                                    <td>
                                                        <p className="completed">Completed</p>
                                                    </td>
                                                    <td>
                                                        <p>$450</p>
                                                        <p className="mdr">$.5</p>
                                                    </td>
                                                </tr>
                                                <tr data-bs-toggle="modal" data-bs-target="#transactionsMod">
                                                    <th scope="row">
                                                        <p>Ron Stewart</p>
                                                        <p className="mdr">Payment Refund</p>
                                                    </th>
                                                    <td>
                                                        <p>11:00 PM</p>
                                                        <p className="mdr">21 Mar 2022</p>
                                                    </td>
                                                    <td>
                                                        <p className="cancelled">Cancelled</p>
                                                    </td>
                                                    <td>
                                                        <p>+$450</p>
                                                        <p className="mdr">No Fees</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <nav aria-label="Page navigation" className="d-flex justify-content-center mt-40">
                                        <ul className="pagination justify-content-center align-items-center mb-40">
                                            <li className="page-item">
                                                <a className="page-link previous" href="javascript:void(0)" aria-label="Previous">
                                                    <i className="fa-solid fa-angles-left" />
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link previous" href="javascript:void(0)" aria-label="Previous">
                                                    <i className="fa-solid fa-angle-left" />
                                                </a>
                                            </li>
                                            <li className="page-item"><a className="page-link" href="javascript:void(0)">1</a></li>
                                            <li className="page-item"><a className="page-link active" href="javascript:void(0)">2</a></li>
                                            <li className="page-item"><a className="page-link" href="javascript:void(0)">3</a></li>
                                            <li className="page-item"><a className="page-link" href="javascript:void(0)">...</a></li>
                                            <li className="page-item">
                                                <a className="page-link next" href="javascript:void(0)" aria-label="Next">
                                                    <i className="fa-solid fa-angle-right" />
                                                </a>
                                            </li>
                                            <li className="page-item">
                                                <a className="page-link next" href="javascript:void(0)" aria-label="Next">
                                                    <i className="fa-solid fa-angles-right" />
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
