import React from "react";
import Sidebar from "../Components/Coman/Sidebar";

export default function Account() {
  return (
    <>
      <Sidebar />
      <section className="dashboard-section body-collapse account">
        <div className="overlay pt-120">
          <div className="container-fruid">
            <div className="main-content">
              <div className="row justify-content-center">
                <div className="col-xxl-11 ">
                  <div className="tab-main">
                    <div className="tab-content mt-40">
                      <div
                        className="tab-pane fade show active"
                        id="account"
                        role="tabpanel"
                        aria-labelledby="account-tab"
                      >
                        <div className="upload-avatar">
                          <div className="avatar-left d-flex align-items-center">
                            <div className="instraction">
                              <div className="owner-info">
                                <ul>
                                  <li className="d-flex gap-2">
                                    <p>Account ID:</p>
                                    <span className="mdr">Rex49484</span>
                                  </li>
                                  <li className="d-flex gap-2">
                                    <p>Joined:</p>
                                    <span className="mdr">Aug 25,2021</span>
                                  </li>
                                  <li className="d-flex gap-2">
                                    <p>Confirm status:</p>
                                    <span className="mdr">80%</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                      
                        </div>
                        <form action="#">
                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="single-input">
                                <label htmlFor="fName">First Name</label>
                                <input
                                  type="text"
                                  id="fName"
                                  placeholder="Alfred"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-input">
                                <label htmlFor="lName">Last Name</label>
                                <input
                                  type="text"
                                  id="lName"
                                  placeholder="Davis"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-input">
                                <label htmlFor="Email">Email</label>
                                <input
                                  type="email"
                                  id="Email"
                                  placeholder="Alfred"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="single-input">
                              <label htmlFor="phone">Phone</label>
                              <input
                                      type="number"
                                      id="phone"
                                      placeholder="(316) 555-0116"
                                    />
                              </div>
                            </div>
                            
                            <div className="col-md-12">
                              <div className="btn-border">
                                <button className="cmn-btn">Account</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="security"
                        role="tabpanel"
                        aria-labelledby="security-tab"
                      >
                        <div className="single-content authentication d-flex align-items-center justify-content-between">
                          <div className="left">
                            <h5>Two Factor Authentication</h5>
                            <p>
                              Two-Factor Authentication (2FA) can be used to
                              help protect your account
                            </p>
                          </div>
                          <div className="right">
                            <button>Enable</button>
                          </div>
                        </div>
                        <div className="change-pass mb-40">
                          <div className="row">
                            <div className="col-sm-6">
                              <h5>Change Password</h5>
                              <p>
                                You can always change your password for security
                                reasons or reset your password in case you
                                forgot it.
                              </p>
                              <a href="javascript:void(0)">Forgot password?</a>
                            </div>
                            <div className="col-sm-6">
                              <form action="#">
                                <div className="row justify-content-center">
                                  <div className="col-md-12">
                                    <div className="single-input">
                                      <label htmlFor="current-password">
                                        Current password
                                      </label>
                                      <input
                                        type="text"
                                        id="current-password"
                                        placeholder="Current password"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="single-input">
                                      <label htmlFor="new-password">
                                        New password
                                      </label>
                                      <input
                                        type="text"
                                        id="new-password"
                                        placeholder="New password"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="single-input">
                                      <label htmlFor="confirm-password">
                                        Confirm New password
                                      </label>
                                      <input
                                        type="text"
                                        id="confirm-password"
                                        placeholder="Confirm New password"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="btn-border w-100">
                                      <button className="cmn-btn w-100">
                                        Update password
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="single-content additional-security">
                          <h5>Additional security</h5>
                          <div className="single-setting">
                            <div className="left">
                              <h6>SMS recovery</h6>
                              <p>Number ending with 1234</p>
                            </div>
                            <div className="right">
                              <button className="active">Disable SMS</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <h6>Autheticator App</h6>
                              <p>Google Authenticator</p>
                            </div>
                            <div className="right">
                              <button>Configure</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <h6>SSL Certificate</h6>
                              <p>Secure Sockets Layer</p>
                            </div>
                            <div className="right">
                              <button>Configure</button>
                            </div>
                          </div>
                        </div>
                        <div className="single-content your-devices">
                          <div className="head-item d-flex align-items-center justify-content-between">
                            <h5>Your devices</h5>
                            <a href="javascript:void(0)">
                              Log out on all devices
                            </a>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <div className="icon-area">
                                <img
                                  src="assets-new/images/icon/iphone.png"
                                  alt="icon"
                                />
                              </div>
                              <div className="text-area">
                                <h6>Iphone 13 Pro Max</h6>
                                <p>New York City · June 20 at 14:00</p>
                              </div>
                            </div>
                            <div className="right">
                              <button>Log out</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <div className="icon-area">
                                <img
                                  src="assets-new/images/icon/ipad.png"
                                  alt="icon"
                                />
                              </div>
                              <div className="text-area">
                                <h6>iPad Pro</h6>
                                <p>New York City · June 20 at 14:00</p>
                              </div>
                            </div>
                            <div className="right">
                              <button>Log out</button>
                            </div>
                          </div>
                          <div className="single-setting">
                            <div className="left">
                              <div className="icon-area">
                                <img
                                  src="assets-new/images/icon/imac.png"
                                  alt="icon"
                                />
                              </div>
                              <div className="text-area">
                                <h6>iMac OSX</h6>
                                <p>New York City · June 20 at 14:00</p>
                              </div>
                            </div>
                            <div className="right">
                              <button>Log out</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane pb-120 fade"
                        id="payment"
                        role="tabpanel"
                        aria-labelledby="payment-tab"
                      >
                        <div className="card-area">
                          <h6>Linked Payment system</h6>
                          <div className="card-content d-flex flex-wrap">
                            <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#transactionsMod"
                              >
                                <img
                                  src="assets-new/images/visa-card.png"
                                  alt="image"
                                  className="w-100"
                                />
                              </button>
                            </div>
                            <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#transactionsMod"
                              >
                                <img
                                  src="assets-new/images/paylio-card.png"
                                  alt="image"
                                  className="w-100"
                                />
                              </button>
                            </div>
                            <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#transactionsMod"
                              >
                                <img
                                  src="assets-new/images/paypal-card.png"
                                  alt="image"
                                  className="w-100"
                                />
                              </button>
                            </div>
                            <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#transactionsMod"
                              >
                                <img
                                  src="assets-new/images/blockchain-card.png"
                                  alt="image"
                                  className="w-100"
                                />
                              </button>
                            </div>
                            <div className="single-card">
                              <button
                                type="button"
                                className="reg w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#addcardMod"
                              >
                                <img
                                  src="assets-new/images/add-new.png"
                                  alt="image"
                                  className="w-100"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="notification"
                        role="tabpanel"
                        aria-labelledby="notification-tab"
                      >
                        <div className="notification-single">
                          <h6>Announcements</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Send payment</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Receive a payment</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Request payment</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Have a problem with a payment</h6>
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round" />
                          </label>
                        </div>
                        <div className="notification-single">
                          <h6>Special Offers</h6>
                          <label className="switch">
                            <input type="checkbox" defaultChecked />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
