import React from 'react'
import Header from '../Coman/Header'
import CoinsHero from './CoinsHero'
import Footer from '../Coman/Footer'

export default function Coins() {
  return (
    <div>
        <Header/>
        <CoinsHero/>
        <Footer/>
    </div>
  )
}
