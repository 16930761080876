/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Pagination = ({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  pages,
}) => {
  return (
    <>
      <nav aria-label="Page navigation" className="d-flex justify-content-center mt-40">
        <ul className="pagination justify-content-center align-items-center mb-40">
          <li className={` page-item ${currentPage === 1 ? "active" : ""}`}>
            <a
              type="button"
              className="page-link previous"
              aria-label="Previous"
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1 ? true : false}
            >
              <span className="btn-border" />
              <i className="fa-solid fa-angles-left" />
            </a>
          </li>
          {currentPage - 2 > 0 ? (
            <li className=" page-item">
              <a
                type="button"
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev - 2)}
              ><span className="btn-border" />
                {currentPage - 2}
              </a>
            </li>
          ) : (
            ""
          )}
          {currentPage - 1 > 0 ? (
            <li className=" page-item">
              <a
                type="button"
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev - 1)}
              ><span className="btn-border" />
                {currentPage - 1}
              </a>
            </li>
          ) : (
            ""
          )}
          <li className=" page-item active">
            <a type="button" disabled={true}>
              <span className="btn-border" />
              {currentPage}
            </a>
          </li>
          {currentPage + 1 <= pages ? (
            <li className=" page-item">
              <a
                type="button"
                className="page-link"
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                <span className="btn-border" />
                {currentPage + 1}
              </a>
            </li>
          ) : (
            ""
          )}
          {currentPage + 2 <= pages ? (
            <li className=" page-item">
              <a
                type="button"

                onClick={() => setCurrentPage((prev) => prev + 2)}
              >
                <span className="btn-border" />
                {currentPage + 2}
              </a>
            </li>
          ) : (
            ""
          )}
          <li
            className={` page-item next ${currentPage === pages ? "active" : ""}`}
          >
            <a
              type="button"
              className="page-link next"
              onClick={() => setCurrentPage(pages)}
              disabled={currentPage === pages ? true : false}
            >
              <i className="fa-solid fa-angles-right" />
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
