import React from "react";

export default function AboutMain() {
  return (
    <div>
      <section className="privacy-content terms">
        <div className="overlay pb-120 pt-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="top-wrapper">
                  <h4>
                  About us
                  </h4>

                  <ul className="d-flex">
                    <li style={{ listStyleType: "disc" }}></li>
                    <li>
                      DollarPay is a fully functional cryptocurrency gateway
                      designed with user-friendliness in mind. Our goal has
                      always been to provide a service that is reliable,
                      trustworthy, and seamless. With a growing community of
                      over 26,412 users, we’ve built strong connections through
                      our commitment to excellence. Our services, including
                      digital wallets and digital invoicing, along with other
                      features, operate effortlessly. We prioritize our users'
                      needs and strive to meet them as quickly as possible. Our
                      mission is to simplify the experience for merchants and
                      vendors new to the crypto world, helping them establish
                      strong communication and secure wallet transactions with
                      their buyers or sellers.
                    </li>
                  </ul>
                  <ul className="d-flex">
                    <li style={{ listStyleType: "disc" }}></li>
                    <li>
                      Connecting with individual websites is a significant focus
                      for us, especially for those using DollarPay plugins like
                      PHP, Laravel, and WordPress. These can be easily
                      integrated through our REST API and comprehensive
                      documentation. We prioritize low withdrawal fees, user
                      privacy, and delivering smooth, fast service. DollarPay's
                      foundation is built on user feedback and advanced
                      technological integration, ensuring we provide the best
                      experience for both current and future users.
                    </li>
                  </ul>
                  <ul className="d-flex">
                    <li style={{ listStyleType: "disc" }}></li>
                    <li>
                      One of the standout features of our gateway is the
                      simplicity of its functions—anyone can swap currencies
                      within a minute or easily list their coin on our platform.
                    </li>
                  </ul>
                  <ul className="d-flex">
                    <li style={{ listStyleType: "disc" }}></li>
                    <li>
                      Additionally, payments are directly forwarded to the
                      seller’s wallet within minutes, thanks to our cutting-edge
                      technology that enables quick transactions. Our Webhook
                      function is a major reason why consumers receive payment
                      notifications so rapidly. We also offer top-notch security
                      through two-factor authentication (2FA), ensuring accounts
                      are well-protected.
                    </li>
                  </ul>
                  <ul className="d-flex">
                    <li style={{ listStyleType: "disc" }}></li>
                    <li>
                      DollarPay is designed to deliver the simplest and fastest
                      service to our customers. We value their feedback and
                      continually strive to improve, ensuring we provide the
                      best possible service moving forward.
                    </li>
                  </ul>

           
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
