import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../Components/Coman/Sidebar'
import { ProjectContext } from '../ProjectContext';
import axios from 'axios';
import Pagination from '../Components/Coman/Pagination';

export default function SloteHistory() {
    const { account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "slot",
                submethod: "getbyid",
                // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);

    return (
        <div>
            <Sidebar />
            <section className="dashboard-section body-collapse transactions">
                <div className="overlay pt-120">
                    <div className="container-fruid">
                        <div className="head-area">
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="transactions-main">
                                    <div className="top-items">
                                        <h6>Slots  History</h6>
                                    </div>
                                    {/* <div className="filters-item">
                                        <div className="single-item">
                                            <select>
                                                <option value={1}>23 Nov 2021- 21 Feb 2022</option>
                                                <option value={2}>23 Feb 2021- 21 Mar 2022</option>
                                                <option value={3}>23 Mar 2021- 21 Apr 2022</option>
                                            </select>
                                        </div>
                                        <div className="single-item">
                                            <select>
                                                <option value={1}>Balance</option>
                                                <option value={2}>Balance</option>
                                                <option value={3}>Balance</option>
                                            </select>
                                        </div>
                                        <div className="single-item">
                                            <select>
                                                <option>All Filters</option>
                                                <option value={1}>Filters 1</option>
                                                <option value={2}>Filters 2</option>
                                                <option value={3}>Filters 3</option>
                                            </select>
                                        </div>
                                        <div className="single-item">
                                            <button>Clear Filters</button>
                                        </div>
                                    </div> */}
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Hash</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Slot Number</th>
                                                    <th scope="col">Package</th>
                                                    <th scope="col">Datetime</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="text-center">
                                                    {isLoading ? "Data is loading" : ""}
                                                </tr>
                                                {!isLoading ? (
                                                    !data.length ? (
                                                        <tr className="text-center">
                                                            <td className="text-center" colSpan={7}>
                                                                <span className="w-100">No data found</span>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        ""
                                                    )
                                                ) : (
                                                    ""
                                                )}

                                                {data.map((ele, i) => {
                                                    return (
                                                        <tr>
                                                            <td >
                                                                {ele.id}
                                                            </td>
                                                            <td>
                                                                <p
                                                                    onClick={() => copyaddress(ele.hash)}
                                                                >
                                                                    # {formatAddress(ele.hash)}{" "}
                                                                    <i className="fa fa-copy"></i>
                                                                </p>
                                                            </td>
                                                            <td>
                                                                $ {ele.amount}
                                                            </td>
                                                            <td >
                                                                {ele.slot_number}
                                                            </td>
                                                            <td className='text-uppercase'>
                                                                {ele.slot_package}
                                                            </td>
                                                            <td>
                                                                {ele.createdAt}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <Pagination
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        pageSize={pageSize}
                                        setPageSize={setPageSize}
                                        pages={pages}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
