import React from "react";

export default function GatewayPlugins() {
  return (
    <div>
      <section className="privacy-content terms">
        <div className="overlay pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="top-wrapper">
                  <div className="safe-data">
                    <h4>Open-Source Payment Gateway Plugins</h4>
                    <p>
                      DollarPay provides payment plugins that are open source,
                      which makes it more reliable as they can be modified. This
                      feature makes plugins more effective & perfect for use.
                    </p>
                    <p className="mt-2">
                      DollarPay has plugins for multiple platforms which are
                      being used in the market by most of the ECommerce portals.
                      So if you want to accept crypto payment on your website or
                      application, use DollarPay’s plugins and make this process
                      convenient for the customer as well as for you to operate
                      and manage.
                    </p>
                    <p className="mt-2">
                      Integrating DollarPay plugins is very easy and a quick
                      process. You can integrate plugins, and begin accepting
                      payment in crypto without costing any extra time with the
                      use of its API and key.
                    </p>

                    {/* <a
                      href="#"
                      class="cmn-btn mb-3 "
                      style={{ width: "220px" }}
                    >
                      Integration Process
                    </a> */}
                  </div>
                  <p className="mt-5">
                    Below is the list of platforms where you can integrate
                    DollarPay’s plugins :
                  </p>
                  <div>
                    <img
                      src="assets/img/new-img/open_source_image.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  {/* <div className="d-flex flex-wrap gap-2 justify-content-between">
                  <a href="#">  <img src="assets/img/new-img/network_1.png" alt="icon" /></a>
                    <span className="br-img-p"></span>
                    <a href="#">   <img src="assets/img/new-img/btc.png" alt="icon" /></a>
                    <span className="br-img-p"></span>
                    <a href="#">   <img
                      src="assets/img/new-img/litecoin.png"
                      alt="icon"
                    />{" "}</a>
                    <span className="br-img-p"></span>
                  <a href="#">  <img src="assets/img/new-img/btc_cash.png" alt="icon" /></a>
                    <span className="br-img-p"></span>
                    <a href="#">   <img src="assets/img/new-img/beth.png" alt="icon" /></a>
                    <span className="br-img-p"></span>
                    <a href="#">  <img src="assets/img/new-img/dogecoin.png" alt="icon" /></a>
                    <span className="br-img-p"></span>
                    <a href="#">  <img src="assets/img/new-img/more.png" alt="icon" /></a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
